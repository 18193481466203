.board > *:not(:last-child) {
  margin-bottom: 64px;
}

.boardAdditional  > *:not(:last-child) {
  margin-bottom: 48px;
}

.boardAdditionalTitle {
  font-size: var(--fs-h1);
}

.boardAdditionalItems {
  display: grid;
  grid-template-columns: 1fr 336px;
  grid-gap: 32px;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .board > *:not(:last-child) {
    margin-bottom: 40px;
  }

  .boardAdditional  > *:not(:last-child) {
    margin-bottom: 24px;
  }

  .boardAdditionalItems {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
}