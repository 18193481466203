.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
}

.gridItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--color-black-light);
    padding: 24px 16px;
    border: 1px solid var(--color-grey);
    border-radius: 8px;
}

.gridItem > *:not(:last-child) {
    margin-bottom: 12px;
}

.gridItemSticker {
    display: flex;
    align-items: baseline;
    background-color: var(--color-grey-light);
    padding: 6px 12px;
    border-radius: 8px;
}

.gridItemSticker > *:not(:last-child) {
    margin-right: 8px;
}

.gridItemColor {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.gridItemTitle {
    font-weight: 600;
}

.gridItemText {}

@media (max-width: 992px) {
    .grid {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .gridItem {
        padding: 12px 8px;
        border-radius: 4px;
    }

    .gridItem > *:not(:last-child) {
        margin-bottom: 8px;
    }
}