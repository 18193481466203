@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  --color-black: #101828;
  --color-black-light: #344054;
  --color-white: #FFFFFF;
  --color-grey: #D0D5DD;
  --color-grey-light: #F7F7F9;
  --color-grey-dark: #667085;
  --color-grey-shadow: #F2F4F7;
  --color-main: #7F56D9;
  --color-main-dark: #6941C6;
  --color-main-light: #E9D7FE;
  --color-main-shadow: #F4EBFF;

  --fs-h1: 32px;
  --fs-h2: 24px;
  --fs-big: 20px;
  --fs-base: 18px;
  --fs-small: 16px;
  --fs-very-small: 12px;
}

@media (max-width: 1200px) {
  :root {
    --fs-h1: 28px;
    --fs-h2: 22px;
  }
}

@media (max-width: 768px) {
  :root {
    --fs-h1: 24px;
    --fs-h2: 20px;
    --fs-big: 18px;
    --fs-base: 16px;
    --fs-small: 14px;
    --fs-very-small: 10px;
  }
}

* {
  box-sizing: border-box;
}

body {
  background-color: var(--color-grey-light);
  color: var(--color-black);
  font-family: "Inter", sans-serif;
  font-size: var(--fs-base);
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  line-height: 1.4;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  line-height: 1.2;
  font-weight: 600;
  word-break: break-word;
  overflow-wrap: anywhere;
  margin-block: 0;
}

svg, img {
  display: block;
  max-width: 100%;
  height: auto;
}

.apexcharts-canvas {
  margin: 0 auto;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}