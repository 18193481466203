.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-small);
  line-height: 1.5;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  padding: 11px 19px;
  border: 1px solid;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.button:disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.7;
}

.button:focus-within {
  outline: none;
}

.button--primary {
  background-color: var(--color-main);
  color: var(--color-white);
}

.button--primary:hover {
  background-color: var(--color-main-dark);
}

.button--primary:focus {
  box-shadow: 0 0 0 4px var(--color-main-shadow);
}

.button--primary:disabled {
  background-color: var(--color-main-light);
}

.button--secondary {
  background-color: var(--color-white);
  color: var(--color-black-light);
  border-color: var(--color-grey);
}

.button--secondary:hover {
  background-color: var(--color-grey-light);
}

.button--secondary:focus {
  box-shadow: 0 0 0 4px var(--color-grey-shadow);
}

.button--secondary:disabled {
  background-color: var(--color-grey-light);
}

.button svg {
  width: 20px;
  height: 20px;
}
.button--icon {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .button--icon {
    padding: 10px;
  }
}

.buttonMarginLeft {
  margin-left: 20px;
  margin-top: 5px;
}

.icon {
  margin-right: 8px;
}
