.field > *:not(:last-child) {
  margin-bottom: 6px;
}

.label {
  display: block;
  font-size: var(--fs-small);
  font-weight: 500;
}

.input {
  height: 48px;
  width: 100%;
  color: var(--color-black);
  font-size: var(--fs-small);
  font-family: inherit;
  padding: 0 16px;
  border: 1px solid var(--color-grey);
  border-radius: 8px;
}

.input::placeholder {
  color: var(--color-grey-dark);
}

.input:focus-within {
  outline: none;
}

.input:focus {
  box-shadow: 0 0 0 4px var(--color-main-shadow);
}

.input:disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.7;
}
