.popupContainer {
  width: 450px;
}
.form {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: scroll;
  height: 450px;
  width: 290px;
  margin: 20px 0;
}
.options {
  width: 350px;
  height: 450px;
  margin: 15px 0;
}
.input {
  margin: 10px 5px 0 0;
  width: 20px;
  height: 20px;
}
.textInput {
  font-weight: 700;
}

.buttons {
  display: flex;
  gap: 15px;
}
