.support {
  background-color: var(--color-white);
  padding: 32px;
  border: 1px solid var(--color-grey);
  border-radius: 12px;
}

.support > *:not(:last-child) {
  margin-bottom: 24px;
}

.supportTitle {
  font-size: var(--fs-h2);
  font-weight: 500;
}

.supportActions > * {
  width: 100%;
}

.supportActions > *:not(:last-child) {
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .support {
    padding: 12px;
    border-radius: 8px;
  }

  .support > *:not(:last-child) {
    margin-bottom: 16px;
  }

  .supportActions > *:not(:last-child) {
    margin-bottom: 12px;
  }
}