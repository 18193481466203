.placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-white);
    text-align: center;
    padding: 64px;
    border: 1px solid var(--color-grey);
    border-radius: 12px;
}

.placeholder > *:not(:last-child) {
    margin-bottom: 24px;
}

.placeholderTitle {
    font-size: var(--fs-h2);
    font-weight: 500;
}

.placeholderText {
    color: var(--color-black-light);
}

@media (max-width: 768px) {
    .placeholder {
        padding: 24px 12px;
        border-radius: 8px;
    }

    .placeholder > *:not(:last-child) {
        margin-bottom: 16px;
    }
}