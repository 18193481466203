.reports > *:not(:last-child) {
  margin-bottom: 16px;
}
.gridItemColor {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.report {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  padding: 24px 16px;
  border: 1px solid var(--color-grey);
  border-radius: 8px;
  cursor: pointer;
}

.report > *:not(:last-child) {
  margin-right: 8px;
}

.reportChat {
  color: var(--color-black-light);
  font-weight: 600;
}

.reportDate {
  color: var(--color-grey-dark);
}

@media (max-width: 768px) {
  .reports > *:not(:last-child) {
    margin-bottom: 12px;
  }

  .report {
    padding: 12px 8px;
  }
}
