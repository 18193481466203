span.flatpickr-day.today:not(.selected) {
  border-bottom-color: var(--color-main);
}

span.flatpickr-day.today:not(.selected):hover {
  background-color: var(--color-main);
  border-color: var(--color-main);
}

span.flatpickr-day.selected {
  background-color: var(--color-main-dark);
  border-color: var(--color-main-dark)
}

span.flatpickr-day.selected:hover {
  background-color: var(--color-main);
  border-color: var(--color-main)
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--color-main);
}